import {
  Card,
  Box,
  Flex,
  ActionIcon,
  Title,
  Tooltip,
  Text,
} from "@mantine/core";
import {
  IconDeviceComputerCamera,
  IconMessageCirclePlus,
} from "@tabler/icons-react";
import { VeltRecorderTool, VeltCommentTool } from "@veltdev/react";

export const CollaborationCard = () => {
  return (
    <Card withBorder radius="md" w="100%" h="100%">
      <Tooltip label="Collaborate with others in the report">
        <Box>
          <Title order={6}>Collaboration tools</Title>
          <Text c="dimmed" size="sm">
            Record, comment, call
          </Text>
        </Box>
      </Tooltip>
      <Flex>
        <Tooltip label="Record audio, video, or screen">
          <Box>
            <VeltRecorderTool type="all">
              <div slot="button">
                <ActionIcon size="sm" variant="white" radius={0}>
                  <IconDeviceComputerCamera size="xs"></IconDeviceComputerCamera>
                </ActionIcon>
              </div>
            </VeltRecorderTool>
          </Box>
        </Tooltip>
        <Tooltip label="Add a comment">
          <Box>
            <VeltCommentTool>
              <div slot="button">
                <ActionIcon size="sm" variant="white">
                  <IconMessageCirclePlus size="xs"></IconMessageCirclePlus>
                </ActionIcon>
              </div>
            </VeltCommentTool>
          </Box>
        </Tooltip>
      </Flex>
      <Card.Section withBorder>
        <Tooltip label="Place recorded videos here">
          <Flex
            h={180}
            id="broker-video-narrative"
            justify={"center"}
            align="center"
          >
            <Text c="dimmed">Place video here</Text>
          </Flex>
        </Tooltip>
      </Card.Section>
    </Card>
  );
};
