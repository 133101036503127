import {
  Button,
  Flex,
  Text,
  Textarea,
  Space,
  Divider,
  Box,
} from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { useFeedback } from "./useFeedback";
import { useForm } from "@mantine/form";
import { premadePrompts } from "./premadePrompts";
import { ScenarioAdvisorReport } from "../../../types";

export const AILoanOfficerAssistantModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{ scenarioAdvisorReport: ScenarioAdvisorReport }>) => {
  // get scenario advisor report from context modal props
  const scenarioAdvisorReport = innerProps.scenarioAdvisorReport;

  // custom hook to display streamed LLM feedback from LLM proxy backend
  const [feedback, { getFeedback, isLoadingFeedback, isSuccessFeedback }] =
    useFeedback(scenarioAdvisorReport);

  // setup for mantine form
  const form = useForm({
    initialValues: {
      prompt: "",
    },
  });

  return (
    <>
      <Flex px="10%" direction="column" gap="md">
        <Text size="lg" fw="600">
          Welcome{" "}
          {typeof scenarioAdvisorReport?.primaryBorrowerFName === "string"
            ? scenarioAdvisorReport?.primaryBorrowerFName
            : null}
          ,
        </Text>
        <Text size="sm">
          This is a space to interface with our AI loan assistant. The loan
          assistant can help you understand things like differences between
          lending scenarios, which lending scenarios align with your goals, how
          making extra payments can reduce your loan term, etc...
        </Text>

        {/* PREMADE PROMPTS */}
        <Text size="sm" fw="500">
          Premade prompts
        </Text>
        <Flex wrap="wrap" gap="xs">
          {premadePrompts.map(({ label, promptKey }) => {
            return (
              <Button
                key={promptKey}
                variant="default"
                onClick={() => getFeedback({ promptKey, prompt: "" })}
              >
                {label}
              </Button>
            );
          })}
        </Flex>

        {/* OPEN PROMPT */}
        <form
          onSubmit={form.onSubmit((values) =>
            getFeedback({ promptKey: "CUSTOM", prompt: values.prompt }),
          )}
        >
          <Textarea
            label="Open prompt"
            placeholder="Ask the AI Lending Assistant something else related to mortgage lending..."
            autosize
            minRows={3}
            {...form.getInputProps("prompt")}
          />
          <Space h="xs" />
          <Flex justify="flex-end">
            <Button type="submit">Prompt</Button>
          </Flex>
        </form>
      </Flex>

      {/* AI LOAN ASSISTANT's FEEDBACK */}
      <Box hidden={!isLoadingFeedback && !isSuccessFeedback}>
        <Space h="md"></Space>
        <Flex px="10%" direction="column" gap="md">
          <Divider my="sm" />
          <Text size="lg" fw="600">
            AI Loan Assistant's Feedback
          </Text>
          <Text size="sm">{feedback}</Text>
          <Text size="xs" c="dimmed">
            Disclaimer: The AI Loan Assistant can make mistakes. Check with your
            human loan officer before making any borrowing decisions.
          </Text>
        </Flex>
      </Box>
    </>
  );
};
