import { FC } from "react";
import { IBarChart } from "./types";
import { Bar } from "react-chartjs-2";
import { Bar as BarType } from "./types";
import type { ChartData, ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const BarChart: FC<IBarChart> = ({
  bars,
  activeScenarioIdx,
  title,
  chartDuration,
  ...props
}) => {
  // create datasets array up here
  const parsedDatasetsForBarChart = [];
  // If only one dataset, create the appropriate data
  if (bars.length === 1) {
    const firstDataset = bars[0];
    parsedDatasetsForBarChart.push({
      data: firstDataset.map((bar) => bar.value),
      backgroundColor: firstDataset.map((bar) => bar.color),
      borderColor: firstDataset.map((bar, index) =>
        index === activeScenarioIdx ? "black" : "transparent",
      ),
      borderWidth: firstDataset.map((bar, index) =>
        index === activeScenarioIdx ? 2 : 0,
      ), // Adjust border width as needed
    });
  } else if (bars.length > 1) {
    // TODO: Labels only currently appropriate for Rent vs Principal Chart, consider refactor if new Grouped Bar Chart added
    const labels = ["Principal", "Rent"];
    // If more than one dataset
    // iterate over each dataset and push onto parsedDatasetsForBarChart
    bars.forEach((currentBarDataset, idx) => {
      parsedDatasetsForBarChart.push({
        label: labels[idx],
        data: currentBarDataset.map((bar) => bar.value),
        backgroundColor: currentBarDataset.map((bar) => bar.color),
        borderColor: currentBarDataset.map((bar, index) =>
          index === activeScenarioIdx ? "black" : "transparent",
        ),
        borderWidth: currentBarDataset.map((bar, index) =>
          index === activeScenarioIdx ? 2 : 0,
        ), // Adjust border width as needed
      });
    });
  } else {
    // error state, should not be possible
    console.error("Incorrect data provided for bar chart");
  }

  const firstDataset = bars[0];

  const data: ChartData<"bar"> = {
    // Create labels based off the first bar chart dataset
    labels: firstDataset.map((bar) => bar.label),
    datasets: parsedDatasetsForBarChart,
  };

  const localPlugins = [ChartDataLabels];

  // Function that finds the max value in our dataset so we can add some padding on top of chart
  const findMaxValue = (bars: BarType[][]) => {
    return bars.reduce(
      (max, currentDataSet) =>
        Math.max(max, ...currentDataSet.map((dataPoint) => dataPoint.value)),
      0,
    );
  };
  // Calculate 10% of max value from our dataset to add to top padding for preventing overlap with chart title
  const paddingValue = findMaxValue(bars) * 0.1;

  const options: ChartOptions<"bar"> = {
    plugins: {
      legend: {
        display: bars.length > 1 ? true : false,
      },
      title: {
        display: true,
        text: `${title} Over ${chartDuration} Years`,
        font: {
          size: 14,
          style: "normal",
        },
        padding: {
          bottom: 20,
        },
      },
      // TODO: for savings over time, implement logic for current scenario, such that if the
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: (value, context) => {
          // Check if the title is "Savings Over Time"
          if (title === "Savings Over Time") {
            // Check if the value is the active scenario index or equal to zero
            if (context.dataIndex === activeScenarioIdx) {
              return "Current";
            } else if (value === 0) {
              return "No Savings";
            }
          }
          // For all other titles, return the formatted value
          return `$${value.toLocaleString()}`;
        },
        font: {
          weight: "bold",
        },
        color: "#444",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += ` $${context.parsed.y.toLocaleString()}`; // Intentional space here for formatting
            }
            return label;
          },
        },
      },
      subtitle: {
        // THIS IS NOT WORKING
        display: true,
        text: `Over ${chartDuration} months`,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
        ticks: {
          display: true,
          padding: 10,
          color: "black",
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: false,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, values) {
            return value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        },
        suggestedMax: findMaxValue(bars) + paddingValue,
      },
    },
  };

  return <Bar data={data} options={options} plugins={localPlugins} />;
};
