import { ContextModalProps } from "@mantine/modals";
import {
  Text,
  Card,
  Tabs,
  Box,
  Flex,
  Space,
  useMantineTheme,
} from "@mantine/core";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { AmortizationTable } from "./AmortizationTable";
import { AmortizationDataRow } from "./types";
import {
  getAmortizationSchedule,
  //@ts-ignore
} from "@morfi/mortgage-utils";
import { IScenario } from "../../pages/ScenarioAdvisorReportPage/ScenarioCard/ScenarioCard";
import { useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
);

interface AmortizationScheduleEntry {
  interest: number;
  payment: number;
  principal: number;
  remainingBalance: number;
  totalInterest: number;
  totalPrincipal: number;
}

export const AmortizationVisualizationModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  scenario: IScenario;
  amortizationData: object;
  monthlyPayment: number;
}>) => {
  //
  const theme = useMantineTheme();
  // Get monthly amortization schedule data
  const {
    purchasePrice,
    downPayment,
    interestRate,
    term: termInMonths,
  } = innerProps.scenario;
  const principal = Number(purchasePrice) - Number(downPayment);
  const termInYears = Number(termInMonths) / 12;

  // TODO: memoize this
  const amortizationSchedule = getAmortizationSchedule(
    principal,
    interestRate,
    termInYears,
  );

  // Data table frequency: Annual vs Monthly
  // using the constant value to conform to onChange Mantine UI requirements
  const [value, setValue] = useState<string>("MONTHLY");

  const handleChangeTableDataFrequency = () => {
    if (value === "ANNUAL") setValue("MONTHLY");
    else if (value === "MONTHLY") setValue("ANNUAL");
  };

  // Function for transforming the data into a format that our table can accept
  const transformAmortizationDataForTable = (
    amortizationData: AmortizationScheduleEntry[],
  ): AmortizationDataRow[] => {
    return amortizationData.map((occurrence, index) => ({
      month: index,
      remaining: occurrence.remainingBalance,
      principalPaid: occurrence.totalPrincipal,
      interestPaid: occurrence.totalInterest,
    }));
  };

  // Function for converting monthly amortization data to yearly
  const convertMonthlyToAnnualAmortization = (
    monthlyData: AmortizationDataRow[],
  ): AmortizationDataRow[] => {
    const annualData: AmortizationDataRow[] = [];

    monthlyData.forEach((row, index) => {
      // Since months are 1-indexed in the data, the 12th month would be at position 11 in 0-indexed array
      if (index % 12 === 0 || index === monthlyData.length - 1) {
        annualData.push({
          remaining: row.remaining,
          principalPaid: row.principalPaid,
          interestPaid: row.interestPaid,
          year: Math.round(index / 12),
        });
      }
    });

    return annualData;
  };

  // Calculate monthly and yearly transformed data to pass down to chart and  table components
  const monthlyTransformedData =
    transformAmortizationDataForTable(amortizationSchedule);
  const yearlyTransformedData = convertMonthlyToAnnualAmortization(
    monthlyTransformedData,
  );

  // Transform yearly data into a format that our line chart accepts
  const chartData = {
    // X-axis labels
    labels: Array.from({ length: amortizationSchedule.length }, (_, i) => i),
    // TODO: update colors here
    datasets: [
      {
        label: "Remaining",
        data: yearlyTransformedData.map((occurrence) => occurrence.remaining),
        borderColor: theme.colors.blue[4],
        backgroundColor: theme.colors.blue[4],
        pointRadius: 0,
      },
      {
        label: "Principal Paid",
        data: yearlyTransformedData.map(
          (occurrence) => occurrence.principalPaid,
        ),
        borderColor: theme.colors.grape[7],
        backgroundColor: theme.colors.grape[7],
        pointRadius: 0,
      },
      {
        label: "Interest Paid",
        data: yearlyTransformedData.map(
          (occurrence) => occurrence.interestPaid,
        ),
        borderColor: theme.colors.yellow[4],
        backgroundColor: theme.colors.yellow[4],
        pointRadius: 0,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      x: {
        // grid: {
        //   display: false,
        // },
        title: {
          display: true,
          text: "Years",
        },
        min: 0,
        max: termInYears,
      },
      y: {
        // grid: {
        //   display: false,
        // },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (context: any) => {
            // context[0] contains the tooltip information for the hovered item
            const yearIndex = context[0].label;
            return `Year ${yearIndex}`;
          },
          label: (context: any) => {
            let label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += `: $${context.parsed.y.toLocaleString()}`; // Intentional space here for formatting
            }
            return label;
          },
        },
      },
      legend: {
        position: "top" as const,
        labels: {
          boxWidth: 25,
          usePointStyle: true,
          pointStyle: "circle",
          padding: 16,
        },
      },
    },
  };

  return (
    <Box>
      <Card>
        {/* TODO: add styling for these pieces of text */}
        <Flex direction={"column"} justify="center" align="center">
          <Text size="xl" fw={700}>
            Amortization Schedule
          </Text>
          <Text size="md" fw={500}>
            Monthly principal & interest
          </Text>
          <Text size="xl" fw={700}>
            ${innerProps.monthlyPayment}
          </Text>
          <Text size="md">{`${termInYears}-year fixed loan term`}</Text>
        </Flex>
        <Tabs defaultValue={"chart"}>
          <Tabs.List>
            <Tabs.Tab value="chart" size={"lg"}>
              Amortization Schedule
            </Tabs.Tab>
            <Tabs.Tab value="breakdown" size={"lg"}>
              Breakdown
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="chart">
            <Space h="md" />

            <Line options={options} data={chartData} />
          </Tabs.Panel>

          <Tabs.Panel value="breakdown">
            <Space h="md" />
            <AmortizationTable
              amortizationDataRows={
                value === "MONTHLY"
                  ? monthlyTransformedData
                  : yearlyTransformedData
              }
              handleChangeTableDataFrequency={handleChangeTableDataFrequency}
              amortizationTableDataFrequency={value}
              setAmortizationTableDataFrequency={setValue}
            />
          </Tabs.Panel>
        </Tabs>
      </Card>
    </Box>
  );
};
