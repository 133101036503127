import {
  Card,
  Flex,
  Button,
  Avatar,
  Space,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconHeadphones,
  IconChevronDown,
  IconMessageChatbot,
  IconPhoneCall,
  IconMail,
  IconMessage,
} from "@tabler/icons-react";
import { VeltHuddleTool } from "@veltdev/react";
import { FC } from "react";

interface ILoanOfficerCardProps {
  scenarioAdvisorReport: any;
}

export const LoanOfficerCard: FC<ILoanOfficerCardProps> = ({
  scenarioAdvisorReport,
}) => {
  return (
    <Card
      withBorder
      bg={"white"}
      radius="md"
      id="broker-personal-info-card"
      h="100%"
      w="100%"
    >
      <Flex direction="column" align="center">
        <Flex justify="space-between" align="center" mb="sm" w="100%" gap="md">
          <Button
            variant="subtle"
            size="xs"
            c="blue"
            pl="8"
            pr="8"
            // TODO: replace with actual data for user
            onClick={() => {
              modals.openContextModal({
                modal: "loanOfficerMoreDetailsModal",
                size: "md",
                innerProps: {
                  loanOfficerFullName: "John Smith",
                  loanVolume: 4300,
                  loansClosed: 710,
                  borrowersHelped: 450,
                  moneySaved: 15430000,
                  phoneNumber: "+1-818-867-5309",
                  email: "john.smith@work.com",
                },
              });
            }}
          >
            Show more
          </Button>
          <Flex>
            <VeltHuddleTool type="all">
              <div slot="button">
                <Tooltip label="Live conference">
                  <Button variant="subtle" color="blue" size="xs" pl="8" pr="8">
                    <IconHeadphones size={16}></IconHeadphones>
                    <IconChevronDown size={16}></IconChevronDown>
                  </Button>
                </Tooltip>
              </div>
            </VeltHuddleTool>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          w="min-content"
        >
          <Flex direction="column" mb="md">
            <Avatar size="xl" variant="filled"></Avatar>
          </Flex>
          <Flex direction="column" align="center">
            <Title order={2} fw={700} c="black">
              John Smith
            </Title>
            <Text c="black" fw={500} mb={24}>
              Loan officer
            </Text>
          </Flex>
          <Button
            variant="subtle"
            size="xs"
            leftSection={<IconMessageChatbot size={18}></IconMessageChatbot>}
            onClick={() => {
              modals.openContextModal({
                modal: "aiLoanOfficerAssistantModal",
                title: "AI Loan Officer Assistant Modal",
                size: "lg",
                innerProps: { scenarioAdvisorReport },
              });
            }}
          >
            AI Loan Officer Assistant
          </Button>
          <Space h="sm" />
          <Button.Group>
            <Button
              size="compact-xs"
              title="440-477-8228"
              variant="subtle"
              leftSection={<IconPhoneCall size={12}></IconPhoneCall>}
            >
              Phone
            </Button>
            <Button
              size="compact-xs"
              title="matthew@morfi.com"
              variant="subtle"
              leftSection={<IconMail size={12}></IconMail>}
            >
              Email
            </Button>
            <Button
              title="SMS"
              variant="subtle"
              size="compact-xs"
              leftSection={<IconMessage size={12}></IconMessage>}
            >
              Text
            </Button>
          </Button.Group>
        </Flex>
      </Flex>
    </Card>
  );
};
