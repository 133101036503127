import { useAuth0 } from "@auth0/auth0-react";
import { useVeltClient } from "@veltdev/react";
import { useEffect } from "react";
import { FC } from "react";

export const MorfiVeltAuthComponent: FC = () => {
  const { user: authenticatedUser } = useAuth0();

  // Get the Velt Client
  const { client } = useVeltClient();

  useEffect(() => {
    const initVelt = async () => {
      if (client) {
        // Create the Velt user object
        const user = {
          userId: authenticatedUser?.sub ?? (Math.random() * 1000).toString(),
          name: authenticatedUser?.nickname,
          email: authenticatedUser?.email,
          photoUrl: authenticatedUser?.picture,
        };
        // Identify the user with the Velt client
        await client.identify(user);
      }
    };
    initVelt().catch(console.error);
  }, [authenticatedUser, client]);
  return <div></div>;
};
