import { Button, Flex, Tooltip } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
import { AddScenarioFormValues } from "./types";
import {
  addScenarioFormValidations,
  getAddScenarioFormInitialValues,
} from "./form";
import { PrimaryScenarioDetailsFieldset } from "./components/PrimaryScenarioDetailsFieldset";
import { OtherScenarioDetailsFieldset } from "./components/OtherScenarioDetailsFieldset";
import { TaxesInsuranceAndFeesFieldset } from "./components/TaxesInsuranceAndFeesFieldset";
import { ScenarioAdvisorReport } from "../../../types";
import { useUpdateScenarioAdvisorReport } from "./requests";
import { notifications } from "@mantine/notifications";

export const AddScenarioModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{ scenarioAdvisorReport: ScenarioAdvisorReport }>) => {
  // get scenario advisor report from context modal props
  const scenarioAdvisorReport = innerProps.scenarioAdvisorReport;

  // mutation function for updating scenario advisor report in backend
  const updateScenarioAdvisorReport = useUpdateScenarioAdvisorReport(
    scenarioAdvisorReport?.reportId,
  );

  // setup form state
  const form = useForm<AddScenarioFormValues>({
    initialValues: getAddScenarioFormInitialValues(),
    validate: addScenarioFormValidations,
  });

  const onSubmit = async () => {
    try {
      // update scenario advisor report with new scenario
      await updateScenarioAdvisorReport({
        scenarios: scenarioAdvisorReport?.scenarios.concat(form.values),
      });

      // close modal and show notification on success
      context.closeModal(id);
      notifications.show({
        message: "Published new Scenario",
      });
    } catch (error) {
      // log and show notification on error
      console.log(error);
      notifications.show({
        message: "An error has occurred",
        color: "red",
      });
    }
  };

  return (
    <Flex direction="column" gap="sm">
      <PrimaryScenarioDetailsFieldset form={form} />
      <OtherScenarioDetailsFieldset form={form} />
      <TaxesInsuranceAndFeesFieldset form={form} />
      <Flex justify="flex-end">
        <Tooltip
          hidden={form.isValid()}
          label="Cannot publish with validation errors"
        >
          <Button onClick={onSubmit} disabled={!form.isValid()}>
            Publish
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
