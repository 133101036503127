import { Avatar, Box, Button, Flex, Text, Title } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { IconMail, IconPhoneCall } from "@tabler/icons-react";
import { boldFont } from "./LoanOfficerMoreDetailsModal.css";

export const LoanOfficerMoreDetailsModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  loanOfficerFullName: string;
  loanVolume: number;
  loansClosed: number;
  borrowersHelped: number;
  moneySaved: number;
  phoneNumber: string;
  email: string;
}>) => {
  return (
    <>
      <Flex direction="column" align="center">
        <Flex direction="column" align="center">
          <Avatar size="xl" variant="filled" mb={12}></Avatar>
          <Title order={2} fw={700} c="black" ta="center">
            John Smith
          </Title>
          <Text c="black" fw={500} mb={24}>
            Loan officer
          </Text>
          <Text size="sm">
            <span className={boldFont}>Loan Volume: </span>
            {innerProps.loanVolume}
          </Text>
          <Text size="sm">
            <span className={boldFont}>Loans Closed: </span>
            {innerProps.loansClosed}
          </Text>
          <Text size="sm">
            <span className={boldFont}>Borrowers Helped: </span>
            {innerProps.borrowersHelped}
          </Text>
          <Text size="sm">
            <span className={boldFont}>Money Saved: </span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(innerProps.moneySaved)}
          </Text>
        </Flex>
        <Box mt={"lg"} display="flex">
          <Flex gap={12}>
            <Button
              variant="subtle"
              size="xs"
              leftSection={<IconPhoneCall size={12}></IconPhoneCall>}
            >
              {innerProps.phoneNumber}
            </Button>
            <Button
              variant="subtle"
              size="xs"
              leftSection={
                <IconMail
                  size={12}
                  href={`mailto:${innerProps.email}`}
                ></IconMail>
              }
            >
              {innerProps.email}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
