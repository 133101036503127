import { FC } from "react";
import {
  useMantineTheme,
  Card,
  UnstyledButton,
  Flex,
  Text,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconPlus } from "@tabler/icons-react";
import { ScenarioAdvisorReport } from "../../../types";

interface IAddScenarioTileProps {
  scenarioAdvisorReport: ScenarioAdvisorReport;
}

export const AddScenarioTile: FC<IAddScenarioTileProps> = (
  scenarioAdvisorReport,
) => {
  const theme = useMantineTheme();

  return (
    <UnstyledButton
      onClick={() => {
        modals.openContextModal({
          modal: "addScenarioModal",
          title: "Add Scenario",
          innerProps: scenarioAdvisorReport,
        });
      }}
    >
      <Card withBorder h="190px" radius="md" miw="425px" maw="500px">
        <Flex direction="column" align="center" justify="center" h="100%">
          <Text size="lg" c={theme.colors.gray[6]}>
            Add Scenario
          </Text>
          <IconPlus color={theme.colors.gray[6]} size={22} stroke={1.5} />
        </Flex>
      </Card>
    </UnstyledButton>
  );
};
