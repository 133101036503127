import { Alert, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { formatUSD } from "./ScenarioSummaryAlert";
import { FC } from "react";
import {
  getAcceleratedSavingsInterest,
  getAcceleratedSavingsTime,
  //@ts-ignore
} from "@morfi/mortgage-utils";

interface IEarlyPayoffAlertProps {
  earlyPayoffAmount: number;
  totalMonthlyPayment: number;
  interestRate: number;
  purchasePrice: number;
  downPayment: number;
  termYrs: number;
}

export const EarlyPayoffAlert: FC<IEarlyPayoffAlertProps> = ({
  earlyPayoffAmount,
  totalMonthlyPayment,
  interestRate,
  purchasePrice,
  downPayment,
  termYrs,
}) => {
  const acceleratedSavingsInterest = formatUSD(
    getAcceleratedSavingsInterest(
      purchasePrice - downPayment,
      interestRate,
      termYrs,
      earlyPayoffAmount,
    ),
  );

  const acceleratedSavingsTime = getAcceleratedSavingsTime(
    purchasePrice - downPayment,
    interestRate,
    termYrs,
    earlyPayoffAmount,
  );

  const acceleratedSavingsTimeYrs = Math.floor(acceleratedSavingsTime / 12);
  const acceleratedSavingsTimeMonths = acceleratedSavingsTime % 12;

  return (
    <Alert
      id="early-payoff-strategy-banner" // Don't change this id, as it's used by our product tour
      miw="100%"
      variant="light"
      color="green"
      title="Early Payoff Strategy"
      icon={<IconInfoCircle />}
      styles={{
        root: { zIndex: -1 }, // Need this, so the velt cursor doesn't get hidden behind the alert
      }}
      radius="md"
    >
      <Text size="sm">
        If you pay an additional{" "}
        <Text size="sm" fw={700} component="span">
          {formatUSD(earlyPayoffAmount)}
        </Text>{" "}
        every month, your monthly payment will be{" "}
        <Text fw={700} component="span">
          {formatUSD(totalMonthlyPayment + earlyPayoffAmount)}
        </Text>
        . Over the term of the loan, you will save{" "}
        <Text fw={700} component="span">
          {acceleratedSavingsInterest}
        </Text>{" "}
        and shorten the life of your loan by{" "}
        <Text fw={700} component="span">
          {acceleratedSavingsTimeYrs} years and {acceleratedSavingsTimeMonths}{" "}
          months
        </Text>
        .
      </Text>
    </Alert>
  );
};
