import { ScenarioAdvisorReport } from "../../../types";
import { Bar } from "../../molecules/BarChart/types";
//@ts-ignore
import {
  getMonthlyPayment,
  calculateNetWorthOverTime,
  calculateInterestOverTime,
  calculateInterestAndMortgageInsuranceOverTime,
  calculatePrincipalPaymentOverTime,
  //@ts-ignore
} from "@morfi/mortgage-utils";
import { barChartTitles } from "./constants";

export const getBarChartData = (
  barChartType: string,
  scenarioReport: ScenarioAdvisorReport,
  primaryColorsArray: any,
  secondaryColorsArray: any,
  activeScenarioIdx: number,
): Bar[][] => {
  const scenarioLabels = [
    "Scenario 1",
    "Scenario 2",
    "Scenario 3",
    "Scenario 4",
  ];
  // Based on the barChartType and the report object, retrieve and transform relevant data for the chart
  if (barChartType === barChartTitles.monthlyPaymentComparison) {
    const barChartDataPoints = scenarioReport.scenarios.map(
      (scenario: any, idx: number) => {
        const {
          purchasePrice,
          downPayment,
          interestRate,
          term: termInMonths,
        } = scenario;
        const principal = Number(purchasePrice) - Number(downPayment);
        const termInYears = termInMonths / 12;
        // Calculate monthly payment
        const monthlyPayment = getMonthlyPayment(
          principal,
          interestRate,
          termInYears,
        );
        const label = scenarioLabels[idx]; // Scenario 1, Scenario 2, etc...

        return {
          label: label,
          value: Math.round(monthlyPayment), // Round to the nearest dollar
          color: primaryColorsArray[idx],
          unit: "$",
        };
      },
    );

    return [barChartDataPoints];
  } else if (barChartType === barChartTitles.totalCashToCloseComparison) {
    const barChartDataPoints = scenarioReport.scenarios.map(
      (scenario: any, idx: number) => {
        const label = scenarioLabels[idx]; // Scenario 1, Scenario 2, etc...

        return {
          label: label,
          value: Math.round(scenario.closingCost), // Round to nearest dollar
          color: primaryColorsArray[idx],
          unit: "$",
        };
      },
    );
    return [barChartDataPoints];
  } else if (barChartType === barChartTitles.netWorthOverTime) {
    const barChartDataPoints = scenarioReport.scenarios.map(
      (scenario: any, idx: number) => {
        const {
          downPayment,
          purchasePrice,
          interestRate,
          term: termInMonths,
        } = scenario;
        const principal = Number(purchasePrice) - Number(downPayment);
        const totalLoanTermInYears = termInMonths / 12;
        // Default value to 30 years if visualization duration is null
        const netWorthCalculationTermInYears = scenarioReport.visualizations
          .netWorthOverTimeVizDuration
          ? scenarioReport.visualizations.netWorthOverTimeVizDuration / 12
          : 30;
        // Calculate monthly payment
        const netWorthOverTime = calculateNetWorthOverTime(
          Number(downPayment),
          netWorthCalculationTermInYears,
          principal,
          interestRate,
          totalLoanTermInYears,
        );
        // For labeling bars in bar chart
        const label = scenarioLabels[idx]; // Scenario 1, Scenario 2, etc...

        return {
          label: label,
          value: Math.round(netWorthOverTime), // Round to the nearest dollar
          color: primaryColorsArray[idx],
          unit: "$",
        };
      },
    );
    return [barChartDataPoints];
  } else if (barChartType === barChartTitles.savingsOverTime) {
    // Extract data from active scenario
    // TODO: update type with the official scenario type
    const activeScenario: any = scenarioReport.scenarios[activeScenarioIdx];
    const {
      downPayment,
      purchasePrice,
      interestRate,
      term: termInMonths,
    } = activeScenario;
    const activeScenarioPrincipal = Number(purchasePrice) - Number(downPayment);
    const activeScenarioTotalLoanTermInYears = termInMonths / 12;
    const activeScenarioSavingsOverTimeVizDuration =
      scenarioReport.visualizations.savingsOverTimeVizDuration;

    const activeScenarioInterestPaid = calculateInterestOverTime(
      activeScenarioPrincipal,
      interestRate,
      activeScenarioTotalLoanTermInYears,
      activeScenarioSavingsOverTimeVizDuration,
    );

    // Get data points as compared to active scenario
    const barChartDataPoints = scenarioReport.scenarios.map(
      (scenario: any, idx: number) => {
        const {
          downPayment,
          purchasePrice,
          interestRate,
          term: termInMonths,
        } = scenario;
        const principal = Number(purchasePrice) - Number(downPayment);
        const totalLoanTermInYears = termInMonths / 12;
        const savingsOverTimeVizDuration =
          scenarioReport.visualizations.savingsOverTimeVizDuration;

        // Calculate total interest paid for the current scenario
        const interestPaid = calculateInterestOverTime(
          principal,
          interestRate,
          totalLoanTermInYears,
          savingsOverTimeVizDuration,
        );

        // Calculate savings compared to the active scenario
        const savings =
          idx === scenarioReport.activeScenarioIdx
            ? 0
            : Math.max(0, activeScenarioInterestPaid - interestPaid);

        // For labeling bars in bar chart
        const label = scenarioLabels[idx]; // Scenario 1, Scenario 2, etc...

        return {
          label: label,
          value: Math.round(savings), // Round to the nearest dollar
          color: primaryColorsArray[idx],
          unit: "$",
        };
      },
    );

    return [barChartDataPoints];
  } else if (
    barChartType === barChartTitles.interestAndMortgageInsuranceOverTime
  ) {
    // Duration for the calculation
    const interestAndMortgageInsuranceOverTimeVizDuration =
      scenarioReport.visualizations
        .interestAndMortgageInsuranceOverTimeVizDuration || 0; // Default to 0 if null

    const barChartDataPoints = scenarioReport.scenarios.map(
      (scenario: any, idx: number) => {
        const {
          downPayment,
          purchasePrice,
          interestRate,
          term: termInMonths,
          mortgageInsurance,
        } = scenario;

        const principal = Number(purchasePrice) - Number(downPayment);
        const totalLoanTermInYears = termInMonths / 12;
        const mortgageInsurancePayment = mortgageInsurance || 0; // Cast null to 0

        // Calculate the total interest and mortgage insurance over time
        const totalInterestAndMortgageInsurance =
          calculateInterestAndMortgageInsuranceOverTime(
            mortgageInsurancePayment,
            principal,
            interestRate,
            totalLoanTermInYears,
            interestAndMortgageInsuranceOverTimeVizDuration,
          );

        // Construct the data point for the current scenario
        return {
          label: scenarioLabels[idx], // Scenario 1, Scenario 2, etc.
          value: Math.round(totalInterestAndMortgageInsurance), // Round to the nearest dollar
          color: primaryColorsArray[idx],
          unit: "$",
        };
      },
    );

    return [barChartDataPoints];
  } else if (barChartType === barChartTitles.rentVsPrincipalOverTime) {
    const rentVsPrincipalOverTimeVizDuration =
      scenarioReport.visualizations.rentVsPrincipalOverTimeVizDuration || 360;

    const monthlyRentPayment = Number(scenarioReport.rentPayment);
    const totalRentPaidOverTime =
      monthlyRentPayment * rentVsPrincipalOverTimeVizDuration;

    // Get bar chart data points for rent paid
    const rentPaidBarChartDataPoints = [];
    for (let i = 0; i < 4; i++) {
      const label = scenarioLabels[i];
      rentPaidBarChartDataPoints.push({
        label: label,
        value: Math.round(totalRentPaidOverTime), // Round to the nearest dollar
        color: secondaryColorsArray[i],
        unit: "$",
      });
    }

    // Get bar chart data points  for principal paid
    const principalPaidBarChartDataPoints = scenarioReport.scenarios.map(
      (scenario: any, idx: number) => {
        const {
          purchasePrice,
          downPayment,
          interestRate,
          term: termInMonths,
        } = scenario;
        const principal = Number(purchasePrice) - Number(downPayment);
        const termInYears = termInMonths / 12;
        // Calculate principal paid over time
        const principalPaidOverTime = calculatePrincipalPaymentOverTime(
          principal,
          interestRate,
          termInYears,
          rentVsPrincipalOverTimeVizDuration,
        );
        const label = scenarioLabels[idx]; // Scenario 1, Scenario 2, etc...

        return {
          label: label,
          value: Math.round(principalPaidOverTime), // Round to the nearest dollar
          color: primaryColorsArray[idx],
          unit: "$",
        };
      },
    );

    return [principalPaidBarChartDataPoints, rentPaidBarChartDataPoints];
  } else {
    // TODO: determine what to do in error handling situation, when we have a Visualization that doesn't match our list of pre-defined functions
    const dummyData = [
      {
        label: scenarioLabels[0],
        value: 100000,
        color: primaryColorsArray[0],
        unit: "$",
      },
      {
        label: scenarioLabels[1],
        value: 100000,
        color: primaryColorsArray[1],
        unit: "$",
      },
      {
        label: scenarioLabels[2],
        value: 100000,
        color: primaryColorsArray[2],
        unit: "$",
      },
      {
        label: scenarioLabels[3],
        value: 100000,
        color: primaryColorsArray[3],
        unit: "$",
      },
    ];
    return [dummyData];
  }
};
