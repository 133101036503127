// constants for premade prompt buttons
export const premadePrompts = [
  { label: "Compare & contrast", promptKey: "COMPARE_AND_CONTRAST" },
  { label: "Reduce loan cost", promptKey: "REDUCE_LOAN_COST" },
  {
    label: "Hopethetical lending scenarios",
    promptKey: "HYPOTHETICAL_LEndING_SCENARIOS",
  },
  { label: "Best scenario for me", promptKey: "BEST_SCENARIO_FOR_ME" },
  { label: "Move up or move down", promptKey: "MOVE_UP_OR_MOVE_DOWN" },
  { label: "First time home buyer", promptKey: "FIRST_TIME_HOME_BUYER" },
  { label: "Cash out refinance", promptKey: "CASH_OUT_REFINANCE" },
  {
    label: "Understanding appraisal gaps",
    promptKey: "UNDERSTANDING_APPRAISAL_GAPS",
  },
  {
    label: "Benefits of debt consolidation",
    promptKey: "BENEFITS_OF_DEBT_CONSOLIDATION",
  },
  { label: "Cost of waiting", promptKey: "COST_OF_WAITING" },
  {
    label: "Federal housing association",
    promptKey: "FEDERAL_HOUSING_ASSOCIATION",
  },
  { label: "Down payment analysis", promptKey: "DOWN_PAYMENT_ANALYSIS" },
];
