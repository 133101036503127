import { GridCol, Skeleton } from "@mantine/core";
import { range } from "lodash";
import { FC } from "react";

export const ScenarioCardLoaderGrid: FC = () => {
  return (
    <>
      {range(0, 4).map((i) => {
        return (
          <GridCol span={{ base: 12, md: 6 }} h={240}>
            <Skeleton visible={true} h="100%" w="100%"></Skeleton>
          </GridCol>
        );
      })}
    </>
  );
};
