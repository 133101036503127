import { FC } from "react";
import { Flex, Image, Box, Text, SegmentedControl } from "@mantine/core";
import { useMantineReactTable, MantineReactTable } from "mantine-react-table";
import { AmortizationDataRow } from "./types";
import { getAmortizationTableColumns } from "./columns";
// import NoDataFallback from "path-to-your-no-data-image.svg";

interface AmortizationTableProps {
  amortizationDataRows: AmortizationDataRow[];
  handleChangeTableDataFrequency: Function;
  setAmortizationTableDataFrequency: (value: string) => void;
  amortizationTableDataFrequency: string;
}

export const AmortizationTable: FC<AmortizationTableProps> = ({
  amortizationDataRows,
  handleChangeTableDataFrequency,
  setAmortizationTableDataFrequency,
  amortizationTableDataFrequency,
}) => {
  const columns = getAmortizationTableColumns(amortizationTableDataFrequency);

  const table = useMantineReactTable<AmortizationDataRow>({
    columns,
    data: amortizationDataRows,
    initialState: {
      showColumnFilters: false,
      columnOrder: [
        "month",
        "year",
        "principalPaid",
        "interestPaid",
        "remaining",
      ],
      // columnSizing:
    },
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableEditing: false,
    enableColumnOrdering: false,
    enableColumnResizing: false,
    enablePinning: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableGrouping: false,
    enableHiding: false,
    enableTopToolbar: false,
    renderEmptyRowsFallback: () => (
      <Flex direction={"column"}>
        <Box>
          <Text fw={600} size={"lg"} ta={"center"} mt={"lg"}>
            No data available
          </Text>
        </Box>
        <Box h={"300px"} style={{ objectFit: "contain" }}>
          <Image
            src={
              "https://as1.ftcdn.net/v2/jpg/01/12/43/90/1000_F_112439022_Sft6cXK9GLnzWjjIkVMj2Lt34RcKUpxm.jpg"
            }
            w={"100%"}
            h={"100%"}
            fit="contain"
          />
        </Box>
      </Flex>
    ),
  });

  return (
    <Flex direction={"column"}>
      <SegmentedControl
        mb={"md"}
        fullWidth={true}
        value={amortizationTableDataFrequency}
        onChange={setAmortizationTableDataFrequency}
        data={[
          { label: "Monthly", value: "MONTHLY" },
          { label: "Annual", value: "ANNUAL" },
        ]}
      />
      {/* // TODO: update this box styling */}
      <Box style={{ overflow: "scroll", maxHeight: "500px" }}>
        <MantineReactTable table={table} />
      </Box>
    </Flex>
  );
};
