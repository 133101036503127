export const scenarioAdvisorProductTourSteps = [
  {
    selector: "#scenario-summary-banner",
    content: "This summarizes the important points of the selected scenario.",
  },
  {
    selector: "#early-payoff-strategy-banner",
    content: "This explains how you can shorten your loan payoff journey.",
  },
  {
    selector: "#broker-personal-info-card",
    content:
      "This displays important basic and contact information about the broker that created this report.",
  },
  {
    selector: "#broker-company-info-card",
    content:
      "This displays important basic and contact information about the broker's company.",
  },
  {
    selector: "#scenario-card-tour",
    content:
      "This is the active lending scenario. The scenario summary, early savings payoff, payment breakdown, and visualizations are based on the active scenario.",
  },
  {
    selector: "#inactive-scenario-card-tour",
    content:
      "This is an inactive lending scenario. Make this the active scenario by clicking on it!",
  },
  {
    selector: "#monthly-payment-breakdown",
    content:
      "This is the monthly payment breakdown for the active lending scenario.",
  },
  {
    selector: "#scenario-comparison-visualizations",
    content:
      "These are some visualizations that can help you better understand how the different lending scenarios compare.",
  },
];
