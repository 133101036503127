import { Flex, Box, Text, Title } from "@mantine/core";
import { VeltPresence, VeltSidebarButton } from "@veltdev/react";
import { FC } from "react";
import { IVeltPresenceEventBody } from "./VeltCollection";

interface IScenarioAdvisorReportHeaderProps {
  borrowerName: string;
  primaryBorrowerEmail: string;
}

// const onPresenceUserChange = (presentUsers: Array<IVeltPresenceEventBody>) => {
//   console.log(
//     "here",
//     scenarioAdvisorReport,
//     scenarioAdvisorReport?.primaryBorrowerEmailId,
//   );
//   if (presentUsers.length === 0) return;
//   presentUsers.forEach((presentUser) => {
//     if (presentUser.email === scenarioAdvisorReport?.primaryBorrowerEmailId) {
//       console.log("here");
//       patchScenarioAdvisorReport({
//         borrowerActiveInReport:
//           presentUser.onlineStatus === "online" ? true : false,
//       });
//     }
//   });
// };

export const ScenarioAdvisorReportHeader: FC<
  IScenarioAdvisorReportHeaderProps
> = ({ borrowerName, primaryBorrowerEmail }) => {
  return (
    <Flex justify="flex-end" mih={40} gap="sm" align="flex-end">
      <Flex direction="column" mr="auto">
        <Title order={3}>Scenario Advisor Report</Title>
        <Text size="sm" c="dimmed">
          Lending scenarios for {borrowerName}
        </Text>
      </Flex>
      <Box>
        <VeltPresence
          flockMode
          onPresenceUserChange={async (
            presence: Array<IVeltPresenceEventBody>,
          ) => {
            console.log(presence, primaryBorrowerEmail);
            if (presence.some((user) => user.email === primaryBorrowerEmail)) {
              // await axios.patch("/here", { something: true });
            }
          }}
        />
      </Box>
      <Box>
        <VeltSidebarButton />
      </Box>
    </Flex>
  );
};
