import { Fieldset, Flex, NumberInput, Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FC } from "react";
import { AddScenarioFormValues } from "../types";

interface IPrimaryScenarioDetailsFieldSetProps {
  form: UseFormReturnType<AddScenarioFormValues>;
}

export const PrimaryScenarioDetailsFieldset: FC<
  IPrimaryScenarioDetailsFieldSetProps
> = ({ form }) => {
  return (
    <Fieldset legend="Scenario Details">
      <Flex direction="row" gap="sm" wrap="wrap">
        <NumberInput
          w={100}
          required
          size="xs"
          label="Purchase Price"
          placeholder="$500,000"
          prefix="$"
          thousandSeparator=","
          valueIsNumericString
          max={1000000000}
          min={0}
          {...form.getInputProps("purchasePrice")}
        />
        <NumberInput
          w={100}
          required
          size="xs"
          label="Down Payment"
          placeholder="$50,000"
          prefix="$"
          thousandSeparator=","
          valueIsNumericString
          max={1000000000}
          min={0}
          {...form.getInputProps("downPayment")}
        />
        <Select
          w={120}
          size="xs"
          label="Amortization type"
          placeholder="Pick an amortization type"
          data={["FIXED", "VARIABLE"]}
          {...form.getInputProps("amortizationType")}
        />
        <NumberInput
          w={60}
          required
          size="xs"
          label="Rate"
          placeholder="6%"
          suffix="%"
          thousandSeparator=","
          valueIsNumericString
          max={100}
          min={0}
          {...form.getInputProps("interestRate")}
        />
        <NumberInput
          w={60}
          required
          size="xs"
          label="APR"
          placeholder="6%"
          suffix="%"
          thousandSeparator=","
          valueIsNumericString
          max={100}
          min={0}
          {...form.getInputProps("annualPercentageRate")}
        />
        <NumberInput
          required
          w={"22%"}
          size="xs"
          label="Term (mos)"
          placeholder="360"
          thousandSeparator=","
          valueIsNumericString
          min={0}
          max={1200}
          {...form.getInputProps("term")}
        />
        <NumberInput
          w={90}
          size="xs"
          label="Closing Costs"
          placeholder="0"
          thousandSeparator=","
          valueIsNumericString
          prefix="$"
          min={0}
          max={1000000}
          {...form.getInputProps("closingCost")}
        />
      </Flex>
    </Fieldset>
  );
};
