import { MRT_ColumnDef } from "mantine-react-table";
import { AmortizationDataRow } from "./types";

// A helper function to format numbers as currency
const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // These options ensure the currency is rounded to two decimal places and presented in the common US currency format
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const getAmortizationTableColumns = (
  amortizationTableDataFrequency: string,
): MRT_ColumnDef<AmortizationDataRow>[] => {
  return [
    {
      accessorKey:
        amortizationTableDataFrequency === "MONTHLY" ? "month" : "year",
      header: amortizationTableDataFrequency === "MONTHLY" ? "Month" : "Year",
      maxSize: 100,
    },
    {
      accessorKey: "principalPaid",
      header: "Principal Paid",
      Cell: ({ cell }) => formatCurrency(cell.getValue() as number),
    },
    {
      accessorKey: "interestPaid",
      header: "Interest Paid",
      Cell: ({ cell }) => formatCurrency(cell.getValue() as number),
    },
    {
      accessorKey: "remaining",
      header: "Remaining Balance",
      Cell: ({ cell }) => formatCurrency(cell.getValue() as number),
      maxSize: 200,
    },
  ];
};
