import { useMutation } from "react-query";
import { queryClient } from "../../..";
// @ts-ignore
import { MorfiAxiosClient } from "@morfi/http-client";

// returns mutation function for updating scenario advisor report in backend
export const useUpdateScenarioAdvisorReport = (reportId: string) => {
  const { mutateAsync: updateScenarioAdvisorReport } = useMutation({
    mutationFn: async (dataToUpdate: { [key: string]: any }) => {
      try {
        const { data } = await MorfiAxiosClient.patch(
          `${process.env.REACT_APP_SCENARIO_ADVISOR_REPORT_BACKEND_SERVICE_URL}/api/v1/tca/${reportId}`,
          { data: dataToUpdate },
        );

        return data;
      } catch (error) {
        console.log(error);
      }
    },
    // on query success, update the cached scenarioAdvisorReport directly instead of having to do a refetch of the data
    onSuccess: async (updatedScenarioAdvisorReport: any) => {
      queryClient.setQueryData(
        ["fetchByReportId", reportId],
        () => updatedScenarioAdvisorReport,
      );
    },
  });

  return updateScenarioAdvisorReport;
};
