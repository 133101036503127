import React, { FC } from "react";
import ReactDOMClient from "react-dom/client";
import App from "./App";
import { MantineProvider, createTheme } from "@mantine/core";
import singleSpaReact from "single-spa-react";
//@ts-ignore
import { MorfiAppShell } from "@morfi/app-shell";
import { BrowserRouter } from "react-router-dom";
import { VeltHuddle, VeltProvider, VeltRecorderNotes } from "@veltdev/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider } from "@auth0/auth0-react";
import { Notifications } from "@mantine/notifications";

// styles
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { AILoanOfficerAssistantModal } from "./components/modals/AILoanOfficerAssistantModal/AILoanOfficerAssistantModal";
import { AddScenarioModal } from "./components/modals/AddScenarioModal/AddScenarioModal";
import { AmortizationVisualizationModal } from "./components/modals/AmortizationVisualizationModal/AmortizationVisualizationModal";
import { LoanOfficerMoreDetailsModal } from "./components/modals/LoanOfficerMoreDetailsModal/LoanOfficerMoreDetailsModal";
import { ReportLegalDisclosureAgreement } from "./components/modals/ReportLegalDisclosureAgreement";
import { ReportProductTourModal } from "./components/modals/ReportProductTourModal";

const theme = createTheme({
  /** Put your mantine theme override here */
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

export const Root: FC = () => {
  return (
    <React.StrictMode>
      <MorfiAppShell>
        <BrowserRouter>
          <MantineProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <Auth0Provider
                domain="https://auth.morfi.com"
                clientId="wB0BMj6TU1U6bkAjPGvHgvGA9QmquFaK"
                authorizationParams={{
                  redirect_uri: window.location.origin,
                  audience: "https://api.morfi.com",
                }}
                cacheLocation="localstorage"
              >
                <ModalsProvider
                  modals={{
                    reportLegalDisclosure: ReportLegalDisclosureAgreement,
                    reportProductTourModal: ReportProductTourModal,
                    aiLoanOfficerAssistantModal: AILoanOfficerAssistantModal,
                    addScenarioModal: AddScenarioModal,
                    amortizationVisualization: AmortizationVisualizationModal,
                    loanOfficerMoreDetailsModal: LoanOfficerMoreDetailsModal,
                  }}
                >
                  <VeltProvider apiKey="OlGnH091QAyB6gTAtaX6">
                    <VeltHuddle />
                    <VeltRecorderNotes />
                    <Notifications />
                    <App />
                  </VeltProvider>
                </ModalsProvider>
              </Auth0Provider>
            </QueryClientProvider>
          </MantineProvider>
        </BrowserRouter>
      </MorfiAppShell>
    </React.StrictMode>
  );
};

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  errorBoundary(err: any, info: any, props: any) {
    // Customize the root error boundary for your microfrontend here.
    return <div>Here is an error boundary!</div>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
