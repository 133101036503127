import { FC } from "react";
import {
  useMantineTheme,
  Card,
  Flex,
  Badge,
  Text,
  Space,
  Button,
  ActionIcon,
  Divider,
  Box,
  Tooltip,
} from "@mantine/core";
import { ScenarioCardData } from "./ScenarioCardData";
//@ts-ignore
import { getMonthlyPayment } from "@morfi/mortgage-utils";
import { modals } from "@mantine/modals";
import { IconHeart } from "@tabler/icons-react";

export interface IScenario {
  id: string;
  appraisalFee: string;
  creditReport: string;
  downPayment: string;
  firstPaymentDate: Date;
  hazardInsurance: string;
  homeownersAssociation: string;
  homeownersInsurance: string;
  includeTaxesInsuranceAndFees: boolean;
  interestRate: string;
  mortgageInsurance: string;
  points: string;
  prepaids: string;
  processingFee: string;
  purchasePrice: string;
  rateBuyDown: string;
  recordFee: string;
  scenarioUUID: string;
  settlementFee: string;
  taxReserves: string;
  term: string;
  titleInsurance: string;
  closingCost: string | number;
  amortizationType: string;
  annualPercentageRate: string | number;
}

interface IScenarioCardProps {
  idx: number;
  scenarioName: string;
  scenarioDescription: string;
  scenario: IScenario;
  isFocusedScenario: boolean;
  setActiveScenarioIdx: Function;
}

function formatUSD(number: number): string {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export const ScenarioCard: FC<IScenarioCardProps> = ({
  scenarioName,
  scenarioDescription,
  scenario,
  isFocusedScenario,
  setActiveScenarioIdx,
  idx,
}) => {
  const theme = useMantineTheme();
  const textColor = isFocusedScenario ? "white" : "black";

  // parse scenario data into numbers
  const downPayment = parseFloat(scenario?.downPayment);
  const purchasePrice = parseFloat(scenario?.purchasePrice);
  const term = parseFloat(scenario?.term);
  const interestRate = parseFloat(scenario?.interestRate);

  const monthlyPayment = getMonthlyPayment(
    purchasePrice - downPayment,
    interestRate,
    term / 12,
  ).toLocaleString("en-US", { maximumFractionDigits: 0 });

  const cashToClose = +scenario?.closingCost ?? 0;

  const annualPercentageRate = (scenario?.annualPercentageRate ?? 0).toString();

  return (
    <Card
      w="100%"
      h="100%"
      bg={isFocusedScenario ? theme.colors.blue[6] : undefined}
      withBorder
      radius="md"
      onClick={() => setActiveScenarioIdx(idx)}
      style={{ cursor: "pointer" }}
    >
      <Flex justify="space-between">
        <Flex direction="column" justify="space-between">
          <Badge
            variant={isFocusedScenario ? "white" : "default"}
            size="md"
            radius="md"
            style={{ textTransform: "none" }}
          >
            {scenarioDescription}
          </Badge>
          <Flex direction="column" style={{ gap: "0.4em " }}>
            <Text
              c={isFocusedScenario ? "white" : theme.colors.blue[6]}
              size="sm"
            >
              Total Monthly Payment
            </Text>
            <Flex align="flex-end">
              <Text
                //
                style={{ position: "relative", bottom: "1px", lineHeight: 1 }}
                size="xl"
                c={textColor}
              >
                $
              </Text>
              <Space style={{ width: "0.3em" }} />
              <Text
                style={{
                  fontSize: "28px",
                  lineHeight: 1,
                }}
                c={textColor}
              >
                {monthlyPayment}
              </Text>
            </Flex>
            <Text
              size="sm"
              c={
                isFocusedScenario ? theme.colors.gray[2] : theme.colors.gray[6]
              }
            >
              {formatUSD(purchasePrice)} Purchase Price
            </Text>
          </Flex>
        </Flex>
        <Flex direction="column" gap="xs">
          <ScenarioCardData
            label="Loan Amount"
            value={formatUSD(purchasePrice - downPayment)}
            textColor={textColor}
          />
          <ScenarioCardData
            label="Down Payment"
            value={formatUSD(downPayment)}
            textColor={textColor}
          />
          <ScenarioCardData
            label="Cash to Close"
            value={formatUSD(cashToClose)}
            textColor={textColor}
          />
        </Flex>
        <Flex direction="column" gap="xs">
          <ScenarioCardData
            label="Loan Type"
            value={scenario.amortizationType}
            textColor={textColor}
          />
          <ScenarioCardData
            label="Rate"
            value={`${interestRate}%`}
            textColor={textColor}
          />
          <ScenarioCardData
            label="APR"
            value={`${annualPercentageRate}%`}
            textColor={textColor}
          />
        </Flex>
      </Flex>
      <Divider mt="xs" ml={-16} mr={-16} />
      <Flex gap="sm" justify={"flex-end"} align="center" mt="sm">
        <Box>
          <Tooltip label="Amortization table, fees, other information...">
            <Button
              variant={isFocusedScenario ? "white" : "outline"}
              size="xs"
              onClick={() => {
                modals.openContextModal({
                  modal: "amortizationVisualization",
                  size: "xl",
                  innerProps: {
                    scenario: scenario,
                    monthlyPayment: monthlyPayment,
                  },
                });
              }}
            >
              Show more
            </Button>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip label="Favorite scenario">
            <ActionIcon
              variant={isFocusedScenario ? "filled" : "light"}
              color={isFocusedScenario ? "pink" : "red"}
              radius="sm"
              size="sm"
            >
              <IconHeart stroke={1.5} size={12} />
            </ActionIcon>
          </Tooltip>
        </Box>
      </Flex>
    </Card>
  );
};
