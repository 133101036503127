import { FC } from "react";
import { Flex, Text } from "@mantine/core";

interface IScenarioCardDataProps {
  label: string;
  value: string;
  textColor: string;
}

export const ScenarioCardData: FC<IScenarioCardDataProps> = ({
  label,
  value,
  textColor,
}) => {
  return (
    <Flex direction="column" style={{ gap: "0.3em" }}>
      <Text fw={600} size="sm" c={textColor}>
        {label}
      </Text>
      <Text size="sm" c={textColor}>
        {value}
      </Text>
    </Flex>
  );
};
