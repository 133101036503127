import { uniqueId } from "lodash";
import { AddScenarioFormValues } from "./types";

export const getAddScenarioFormInitialValues = (): AddScenarioFormValues => {
  return {
    scenarioUUID: uniqueId("lending_scenario_"),

    // property information
    purchasePrice: null,
    downPayment: null,
    interestRate: null,
    annualPercentageRate: undefined,
    term: null,
    closingCost: undefined,
    points: null,
    prepaids: null,
    firstPaymentDate: null,
    rebate: undefined,

    // taxes, insurance, fees, and utilities
    includeTaxesInsuranceAndFees: false,
    mortgageInsurance: null,
    homeownersInsurance: null,
    homeownersAssociation: null,
    hazardInsurance: null,
    appraisalFee: null,
    creditReport: null,
    recordFee: null,
    rateBuyDown: null,
    taxReserves: null,
    titleInsurance: null,
    processingFee: null,
    settlementFee: null,
    threadId: null,
  };
};

export const addScenarioFormValidations = {
  purchasePrice: (value: any) =>
    validateRequired(value) ? null : "Purchase price is required",
  downPayment: (value: any) =>
    validateRequired(value) ? null : "Down payment is required",
  interestRate: (value: any) =>
    validateRequired(value) ? null : "Interest rate is required",
  term: (value: any) =>
    validateRequired(value) ? null : "Loan term is required",
  firstPaymentDate: (value: any) => {
    return value ? null : "First payment date is required";
  },
};

export const validateRequired = (value: string | null | undefined) => {
  if (typeof value === "number") return Number.isFinite(value);
  return !!value?.length;
};
