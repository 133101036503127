import {
  Grid,
  GridCol,
  Card,
  Flex,
  Box,
  useMantineTheme,
  Skeleton,
  Select,
} from "@mantine/core";
import { useVeltClient } from "@veltdev/react";
import { useEffect, useMemo, useState } from "react";
import { MonthlyPaymentBreakdownCard } from "./MonthlyPaymentBreakdownCard";
import { useParams } from "react-router-dom";
import { BarChart } from "../../molecules/BarChart/BarChart";
import { useMutation, useQuery } from "react-query";
//@ts-ignore
import { MorfiAxiosClient } from "@morfi/http-client";
import { OptIntoSmsWithBrokerDialog } from "./OptIntoSmsBrokerDialog";
import { modals } from "@mantine/modals";
import { reportLegalDisclosureBody } from "../../modals/ReportLegalDisclosureAgreement";
import { useTour } from "@reactour/tour";
import { ScenarioCard } from "./ScenarioCard/ScenarioCard";
import { AddScenarioTile } from "./AddScenarioTile";
import { range } from "lodash";
import { ScenarioSummaryAlert } from "./ScenarioSummaryAlert";
import { EarlyPayoffAlert } from "./EarlyPayoffAlert";
import { ScenarioCardLoaderGrid } from "./ScenarioCardLoaderGrid";
import { ScenarioAdvisorReportHeader } from "./ReportHeader";
import { LoanOfficerCard } from "./LoanOfficerCard";
import { CompanyCard } from "./CompanyCard";
import { CollaborationCard } from "./CollaborationCard";
import { VeltScenarioAdvisorCollection } from "./VeltCollection";
import { getBarChartData } from "./utils";
import { Bar } from "../../molecules/BarChart/types";
import { barChartTitles } from "./constants";

// Need a type for the visualizations
// type BarChartTitles =
//   | "Monthly Payment Comparison"
//   | "Total Cash To Close Comparison"
//   | "Net Worth Over Time"
//   | "Savings Over Time"
//   | "Interest & Mortgage Insurance Over Time"
//   | "Rent Vs Principal Over Time";

export const ScenarioAdvisorReportPage = () => {
  // Get the Velt client
  const { client } = useVeltClient();
  const theme = useMantineTheme();
  const { reportId } = useParams(); // Get Scenario Advisor report id from url params

  // Creating constant for bar chart colors so we can later make it dynamic/customized
  // Can be passed down to the BarChart component
  const primaryBarChartColorsArray = useMemo(() => {
    return [
      theme.colors.blue[4],
      theme.colors.blue[6],
      theme.colors.blue[8],
      theme.colors.blue[5],
    ];
  }, [theme.colors.blue]);
  const secondaryBarChartColorsArray = useMemo(() => {
    return [
      theme.colors.orange[4],
      theme.colors.orange[6],
      theme.colors.orange[8],
      theme.colors.orange[5],
    ];
  }, [theme.colors.orange]);

  // Get report id, from backend
  const {
    data: scenarioAdvisorReport,
    isLoading: isLoadingScenarioAdvisorReport,
  } = useQuery(["fetchByReportId", reportId], async () => {
    const { data } = await MorfiAxiosClient.get(
      `${process.env.REACT_APP_SCENARIO_ADVISOR_REPORT_BACKEND_SERVICE_URL}/api/v1/tca/${reportId}`,
    );
    return data[0];
  });

  // Patch report
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutate: patchScenarioAdvisorReport,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isLoadingUpdateScenarioAdvisorReport,
  } = useMutation(["patchByReportId", reportId], async (variables: object) => {
    const { data } = await MorfiAxiosClient.patch(
      `${process.env.REACT_APP_SCENARIO_ADVISOR_REPORT_BACKEND_SERVICE_URL}/api/v1/tca/${reportId}`,
      {
        data: {
          ...variables,
        },
      },
    );
    return data[0];
  });

  const [activeScenarioIdx, setActiveScenarioIdx] = useState(0);

  // Set default active bar chart
  const [activeBarChart, setActiveBarChart] = useState(
    "Monthly Payment Comparison",
  );
  const [activeBarChartData, setActiveBarChartData] = useState<Bar[][] | null>(
    null,
  );
  // onChange of the activeBarChart, we update the data passed down to the bar chart
  useEffect(() => {
    // Get data for the active chart if scenarioAdvisorReport has been fetched
    if (scenarioAdvisorReport) {
      const barChartData = getBarChartData(
        activeBarChart,
        scenarioAdvisorReport,
        primaryBarChartColorsArray,
        secondaryBarChartColorsArray,
        activeScenarioIdx,
      );
      // Set barChartData
      if (barChartData) setActiveBarChartData(barChartData);
    }
  }, [
    activeBarChart,
    activeScenarioIdx,
    primaryBarChartColorsArray,
    secondaryBarChartColorsArray,
    scenarioAdvisorReport,
  ]);

  const [isAcceptedLegalDisclosure, setIsAcceptedLegalDisclosure] =
    useState(false);

  // Use product tour
  const { setIsOpen } = useTour();

  // Open Legal Disclosure
  useEffect(() => {
    modals.openContextModal({
      modal: "reportLegalDisclosure",
      title: "Disclosure",
      closeOnClickOutside: false,
      closeOnEscape: false,
      withCloseButton: false,
      innerProps: {
        modalBody: reportLegalDisclosureBody(
          "Mortgage Company Name",
          "Mortgage Company Address",
          "www.somenonexistentmortgagecompany.com",
          "#000000",
        ),
        setIsAcceptedLegalDisclosure: setIsAcceptedLegalDisclosure,
      },
    });
  }, []);

  // Open product tour
  useEffect(() => {
    if (isAcceptedLegalDisclosure && setIsOpen) {
      modals.openContextModal({
        modal: "reportProductTourModal",
        title: "Report Product Tour",
        innerProps: {
          setProductTourOpen: setIsOpen,
        },
      });
    }
  }, [isAcceptedLegalDisclosure, setIsOpen]);

  useEffect(() => {
    if (client && reportId) {
      // Set a document ID
      client.setDocumentId(`scenario-advisor-report-${reportId}`);
    }
  }, [client, reportId]);

  return (
    <Flex direction="column">
      <VeltScenarioAdvisorCollection></VeltScenarioAdvisorCollection>
      <OptIntoSmsWithBrokerDialog hasOptedIntoText={false} />
      <Box mb={"sm"}>
        <Skeleton visible={isLoadingScenarioAdvisorReport}>
          {scenarioAdvisorReport?.primaryBorrowerEmailId && (
            <ScenarioAdvisorReportHeader
              borrowerName={`${scenarioAdvisorReport?.primaryBorrowerFName} ${scenarioAdvisorReport?.primaryBorrowerLName}`}
              primaryBorrowerEmail={
                scenarioAdvisorReport.primaryBorrowerEmailId
              }
            ></ScenarioAdvisorReportHeader>
          )}
        </Skeleton>
      </Box>
      <Grid>
        <GridCol span={{ base: 12, md: 3 }} order={{ base: 1, md: 2 }}>
          <Skeleton visible={isLoadingScenarioAdvisorReport}>
            <Grid>
              <GridCol span={{ base: 12, sm: 4, md: 12 }}>
                <LoanOfficerCard
                  scenarioAdvisorReport={scenarioAdvisorReport}
                ></LoanOfficerCard>
              </GridCol>
              <GridCol span={{ base: 12, xs: 6, sm: 4, md: 12 }}>
                <CompanyCard></CompanyCard>
              </GridCol>
              <GridCol span={{ base: 12, xs: 6, sm: 4, md: 12 }}>
                <CollaborationCard />
              </GridCol>
            </Grid>
          </Skeleton>
        </GridCol>
        <GridCol
          id="main-report-content-grid-col"
          span={{ base: 12, md: 9 }}
          order={{ base: 2, md: 1 }}
        >
          <Grid>
            <GridCol id="scenario-summary-grid-col">
              <Skeleton visible={isLoadingScenarioAdvisorReport}>
                <ScenarioSummaryAlert
                  activeScenarioIdx={activeScenarioIdx}
                  scenario={{
                    purchasePrice:
                      scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                        ?.purchasePrice ?? 0,
                    downPayment:
                      scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                        ?.downPayment ?? 0,
                    interestRate:
                      scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                        ?.interestRate ?? 0,
                    term:
                      scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                        ?.term ?? 360,
                    closingCost:
                      scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                        ?.closingCost ?? 0,
                  }}
                  scenarioCount={scenarioAdvisorReport?.scenarios?.length ?? 0}
                />
              </Skeleton>
            </GridCol>
            <GridCol id="scenario-early-payoff-summary-grid-col">
              <Skeleton visible={isLoadingScenarioAdvisorReport}>
                <EarlyPayoffAlert
                  earlyPayoffAmount={
                    scenarioAdvisorReport?.visualizations.earlyPayoffAmount ??
                    100 ??
                    0
                  }
                  totalMonthlyPayment={
                    scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                      ?.totalMonthlyPayment ?? 0
                  }
                  interestRate={
                    scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                      ?.interestRate ?? 0
                  }
                  purchasePrice={
                    scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                      ?.purchasePrice ?? 0
                  }
                  downPayment={
                    scenarioAdvisorReport?.scenarios[activeScenarioIdx]
                      ?.downPayment ?? 0
                  }
                  termYrs={
                    scenarioAdvisorReport?.scenarios[activeScenarioIdx]?.term /
                      12 ?? 360
                  }
                />
              </Skeleton>
            </GridCol>
            <GridCol id="scenario-grid-grid-col">
              <Grid>
                {isLoadingScenarioAdvisorReport ? (
                  <ScenarioCardLoaderGrid></ScenarioCardLoaderGrid>
                ) : null}
                {scenarioAdvisorReport?.scenarios?.map(
                  (scenario: any, idx: number) => {
                    return (
                      <GridCol span={{ base: 12, sm: 6, md: 6 }}>
                        <Flex
                          id={
                            activeScenarioIdx === idx
                              ? "scenario-card-tour"
                              : "inactive-scenario-card-tour"
                          }
                          h="100%"
                          w="100%"
                        >
                          <ScenarioCard
                            idx={idx}
                            scenarioName={`Scenario ${idx + 2}`}
                            scenarioDescription={
                              idx === 0
                                ? "Lowest interest rate"
                                : "Small monthly payment"
                            }
                            scenario={scenario}
                            isFocusedScenario={activeScenarioIdx === idx}
                            setActiveScenarioIdx={setActiveScenarioIdx}
                          ></ScenarioCard>
                        </Flex>
                      </GridCol>
                    );
                  },
                )}
                {scenarioAdvisorReport?.scenarios?.length < 4
                  ? range(0, 4 - scenarioAdvisorReport?.scenarios?.length).map(
                      (i) => {
                        return (
                          <GridCol span={{ base: 12, sm: 6, md: 6 }}>
                            <AddScenarioTile
                              scenarioAdvisorReport={scenarioAdvisorReport}
                            />
                          </GridCol>
                        );
                      },
                    )
                  : null}
              </Grid>
            </GridCol>
            <GridCol
              id="monthly-payment-breakdown-grid-col"
              span={{ base: 12, sm: 4, md: 4, lg: 4, xl: 3 }}
            >
              <Skeleton
                visible={isLoadingScenarioAdvisorReport}
                h="100%"
                w="100%"
              >
                <Box id="monthly-payment-breakdown" w={"100%"} h="100%">
                  <MonthlyPaymentBreakdownCard
                    scenario={
                      scenarioAdvisorReport?.scenarios?.[activeScenarioIdx]
                    }
                  ></MonthlyPaymentBreakdownCard>
                </Box>
              </Skeleton>
            </GridCol>

            <GridCol span={{ base: 12, sm: 8 }}>
              <Skeleton visible={isLoadingScenarioAdvisorReport} h="100%">
                <Card
                  id="scenario-comparison-visualizations"
                  withBorder
                  h="100%"
                  radius="md"
                >
                  <Flex direction="column" justify="stretch" h="100%">
                    <Select
                      mb={"sm"}
                      label="Visualization"
                      placeholder={barChartTitles.monthlyPaymentComparison}
                      data={[
                        barChartTitles.monthlyPaymentComparison,
                        barChartTitles.totalCashToCloseComparison,
                        barChartTitles.netWorthOverTime,
                        barChartTitles.savingsOverTime,
                        barChartTitles.interestAndMortgageInsuranceOverTime,
                        barChartTitles.rentVsPrincipalOverTime,
                      ]}
                      onChange={(_value, option) => {
                        if (_value) setActiveBarChart(_value);
                      }}
                    />
                    {activeBarChartData && (
                      <Flex flex="1">
                        <BarChart
                          bars={activeBarChartData}
                          activeScenarioIdx={activeScenarioIdx}
                          title={activeBarChart}
                          chartDuration={30}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Card>
              </Skeleton>
            </GridCol>
          </Grid>
        </GridCol>
      </Grid>
    </Flex>
  );
};
