import { Fieldset, Flex, NumberInput, Switch, rem } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FC, useState } from "react";
import { AddScenarioFormValues } from "../types";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";

interface IOtherScenarioDetailsFieldsetProps {
  form: UseFormReturnType<AddScenarioFormValues>;
}

export const OtherScenarioDetailsFieldset: FC<
  IOtherScenarioDetailsFieldsetProps
> = ({ form }) => {
  const [isInputFieldOpen, setIsInputFieldOpen] = useState(true);

  return (
    <Fieldset>
      <Flex direction={"column"}>
        <Switch
          size="xs"
          checked={isInputFieldOpen}
          label="Other Scenario Details"
          description="Discounts, rebates, lock period, etc..."
          onChange={() => {
            setIsInputFieldOpen((state: boolean) => !state);
          }}
        />
        {isInputFieldOpen ? (
          <Flex wrap={"wrap"} gap="sm">
            <NumberInput
              w={100}
              size="xs"
              label="Discount"
              placeholder="0"
              thousandSeparator=","
              valueIsNumericString
              prefix="$"
              min={0}
              max={1000000}
              {...form.getInputProps("discount")}
            ></NumberInput>
            <NumberInput
              w={100}
              size="xs"
              label="Rebate"
              placeholder="0"
              thousandSeparator=","
              valueIsNumericString
              prefix="$"
              min={0}
              max={1000000}
              {...form.getInputProps("rebate")}
            ></NumberInput>
            <NumberInput
              w={120}
              size="xs"
              label="Lock period (days)"
              placeholder="0"
              thousandSeparator=","
              valueIsNumericString
              min={0}
              max={1000}
              {...form.getInputProps("lockPeriod")}
            ></NumberInput>
            <NumberInput
              w={"25%"}
              size="xs"
              label="Points"
              placeholder="0.5"
              thousandSeparator=","
              valueIsNumericString
              min={0}
              max={1000}
              {...form.getInputProps("points")}
            ></NumberInput>
            <NumberInput
              w={"25%"}
              size="xs"
              label="Prepaids"
              placeholder="$5,000"
              prefix="$"
              thousandSeparator=","
              valueIsNumericString
              min={0}
              {...form.getInputProps("prepaids")}
            ></NumberInput>
            <DatePickerInput
              w={"28%"}
              size="xs"
              required
              {...form.getInputProps("firstPaymentDate")}
              leftSection={
                <IconCalendar
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={1.5}
                />
              }
              placeholder={new Date().toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
              })}
              valueFormat="M/D/YY"
              leftSectionPointerEvents="none"
              label="First Payment"
            />
          </Flex>
        ) : null}
      </Flex>
    </Fieldset>
  );
};
