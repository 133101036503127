import { Button, Flex, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";

export const ReportProductTourModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{ modalBody: string; setProductTourOpen: Function }>) => (
  <>
    <Text size="sm" c="dimmed" mb={"sm"}>
      Welcome to your new report!
    </Text>
    <Text size="sm" c="dimmed">
      Take our product tour to learn about the different parts of your new
      Scenario Advisor report.
    </Text>
    <Flex justify={"right"}>
      <Button
        variant="subtle"
        onClick={() => {
          context.closeModal(id);
        }}
      >
        No Thanks
      </Button>
      <Button
        onClick={() => {
          innerProps.setProductTourOpen(true);
          context.closeModal(id);
        }}
      >
        Start Tour
      </Button>
    </Flex>
  </>
);
