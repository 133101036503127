import {
  VeltCursor,
  VeltComments,
  VeltCommentsSidebar,
  VeltRecorderControlPanel,
  VeltRecorderPlayer,
} from "@veltdev/react";
import { FC } from "react";
import { MorfiVeltAuthComponent } from "../../velt/MorfiVeltAuthComponent/MorfiVeltAuthComponent";

export interface IVeltPresenceEventBody {
  email: string;
  name: string;
  onlineStatus: string;
  timestamp: number;
  userId: string;
}

//Helper functional component to abstract away all the velt stuff we are using on Scenario Advisor Report Pages
export const VeltScenarioAdvisorCollection: FC = () => {
  return (
    <>
      <MorfiVeltAuthComponent />
      <div>
        <VeltCursor />
        <VeltComments />
        <VeltCommentsSidebar />
        <VeltRecorderControlPanel />
        <VeltRecorderPlayer />
      </div>
    </>
  );
};
