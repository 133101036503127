import { Dialog, Group, TextInput, Button, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { FC } from "react";

interface IOptIntoSmsWithBrokerTileProps {
  hasOptedIntoText: boolean;
}

export const OptIntoSmsWithBrokerDialog: FC<IOptIntoSmsWithBrokerTileProps> = ({
  hasOptedIntoText,
}) => {
  // OptIn-OptOut SMS Messaging
  const [isOptInOptOutSmsMessaging, isOptInOptOutSmsMessagingHandler] =
    useDisclosure(true);

  return !hasOptedIntoText ? (
    <Dialog
      opened={isOptInOptOutSmsMessaging}
      withCloseButton
      onClose={() => {
        isOptInOptOutSmsMessagingHandler.close();
      }}
      size="md"
      radius="md"
    >
      <Text size="sm" mb="xs" fw={500}>
        Connect with your loan officer by SMS
      </Text>

      <Group align="flex-end">
        <TextInput
          placeholder="+1 (000) 000-0000"
          style={{ flex: 1 }}
          type="tel"
        />
        <Button onClick={() => {}}>Text Me</Button>
      </Group>
    </Dialog>
  ) : null;
};
