import { Button, Text } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";

export const reportLegalDisclosureBody = (
  mortgageCompanyName: string,
  mortgageCompanyAddress: string,
  companyWebsiteOrLicenseUrl: string,
  nmlsId: string,
) => {
  return `Your actual rate, payment, and costs could be higher. Get an official Loan Estimate before choosing a loan. The Scenario Advisor report is based on (i) information provided by you, (ii) estimates of interest rates, your ability to save, your tax bracket, closing costs and other amounts, (iii) currently available loan programs, and/or (iv) information and assumptions discussed with your advisor, all of which might change over time. This is not a mortgage loan approval nor a commitment to lend. All loans subject to underwriter approval. Terms and conditions apply, subject to change without notice. ${mortgageCompanyName} ${mortgageCompanyAddress}; For more licensing information, please visit ${companyWebsiteOrLicenseUrl}`;
};

export const ReportLegalDisclosureAgreement = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  modalBody: string;
  setIsAcceptedLegalDisclosure: Function;
}>) => {
  return (
    <>
      <Text size="sm">{innerProps.modalBody}</Text>
      <Button
        fullWidth
        mt="md"
        onClick={() => {
          innerProps.setIsAcceptedLegalDisclosure(true);
          context.closeAll();
        }}
      >
        Accept disclosure
      </Button>
    </>
  );
};
