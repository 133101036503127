// Bar chart titles
export const barChartTitles = {
  monthlyPaymentComparison: "Monthly payment comparison",
  totalCashToCloseComparison: "Total cash to close comparison",
  netWorthOverTime: "Net worth over time",
  savingsOverTime: "Savings over time",
  interestAndMortgageInsuranceOverTime:
    "Interest & mortgage insurance over time",
  rentVsPrincipalOverTime: "Rent vs principal over time",
};
