import { Fieldset, Flex, Switch, NumberInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { FC, useState } from "react";
import { AddScenarioFormValues } from "../types";

interface ITaxesInsuranceAndFeesFieldsetProps {
  form: UseFormReturnType<AddScenarioFormValues>;
}

export const TaxesInsuranceAndFeesFieldset: FC<
  ITaxesInsuranceAndFeesFieldsetProps
> = ({ form }) => {
  const [isInputFieldOpen, setIsInputFieldOpen] = useState(false);

  return (
    <Fieldset>
      <Flex direction={"column"} gap="sm">
        <Switch
          size="xs"
          label="Taxes, Insurance, Fees, and Utilities"
          description="Describe other costs associated with this scenario"
          {...form.getInputProps("includeTaxesInsuranceAndFees")}
          onChange={() => {
            setIsInputFieldOpen((state) => !state);
          }}
        />
        {isInputFieldOpen ? (
          <Flex direction="column" gap="sm">
            <Flex gap="sm">
              <NumberInput
                size="xs"
                label="Mortgage Ins."
                placeholder="$200"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("mortgageInsurance")}
              />
              <NumberInput
                size="xs"
                label="Homeowner Ins."
                prefix="$"
                placeholder="$150"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("homeownersInsurance")}
              />
              <NumberInput
                size="xs"
                label="HOA"
                prefix="$"
                placeholder="$175"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("homeownersAssociation")}
              />
            </Flex>
            <Flex gap="sm">
              <NumberInput
                size="xs"
                label="Hazard Ins."
                placeholder="$70"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("hazardInsurance")}
              />
              <NumberInput
                size="xs"
                label="Appraisal Fee"
                placeholder="$300"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("appraisalFee")}
              />
              <NumberInput
                size="xs"
                label="Credit Report"
                placeholder="$70"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("creditReport")}
              />
            </Flex>
            <Flex gap="sm">
              <NumberInput
                size="xs"
                label="Record Fee"
                placeholder="$150"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("recordFee")}
              />
              <NumberInput
                size="xs"
                label="Rate Buy Down"
                placeholder="$3,000"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("rateBuyDown")}
              />
              <NumberInput
                size="xs"
                label="Tax Reserves"
                placeholder="$5,000"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("taxReserves")}
              />
            </Flex>
            <Flex gap="sm">
              <NumberInput
                size="xs"
                label="Title Ins."
                placeholder="$50"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("titleInsurance")}
              />
              <NumberInput
                size="xs"
                label="Processing Fee"
                placeholder="$90"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("processingFee")}
              />
              <NumberInput
                size="xs"
                label="Settlement Fee"
                placeholder="$120"
                prefix="$"
                thousandSeparator=","
                valueIsNumericString
                min={0}
                {...form.getInputProps("settlementFee")}
              />
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Fieldset>
  );
};
