import { Card, Flex, Box, Badge, Text, Title, Tooltip } from "@mantine/core";
import { DonutChart } from "../../molecules/DonutChart/DonutChart";
//@ts-ignore
import { getMonthlyPayment } from "@morfi/mortgage-utils";
import { FC } from "react";

interface IMonthlyPaymentBreakdownCardProps {
  scenario: {
    appraisalFee: number;
    creditReport: number;
    downPayment: number;
    firstPaymentDate: Date;
    hazardInsurance: number;
    homeownersAssociation: number;
    homeownersInsurance: number;
    includeTaxesInsuranceAndFees: boolean;
    interestRate: string;
    mortgageInsurance: number;
    points: number;
    prepaids: number;
    processingFee: number;
    purchasePrice: number;
    rateBuyDown: number;
    recordFee: number;
    scenarioUUID: string;
    settlementFee: number;
    taxReserves: number;
    term: number;
    titleInsurance: number;
    earlyPayoffAmount: number;
  };
}
export const MonthlyPaymentBreakdownCard: FC<
  IMonthlyPaymentBreakdownCardProps
> = ({ scenario }) => {
  return (
    <Card withBorder radius="md" h="100%">
      <Card.Section p={16}>
        <Flex mt={"lg"} direction="column">
          <Tooltip label="Scenario 1 monthly payment">
            <Badge
              size="xs"
              variant="filled"
              pos={"absolute"}
              top={16}
              left={16}
            >
              Scenario 1
            </Badge>
          </Tooltip>
          <Title order={4} mb={"sm"}>
            Payment Breakdown
          </Title>
          <Box pos="relative" w={180} h={180}>
            <Box pos={"absolute"} w={56} h={38} top={70} left={63} ta="center">
              <Text fw={500} size="sm">
                {getMonthlyPayment(
                  scenario?.purchasePrice - scenario?.downPayment,
                  Number.parseFloat(scenario?.interestRate),
                  scenario?.term / 12,
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Text>
              <Text fw={100} size="xs" c="gray">
                per month
              </Text>
            </Box>
            <DonutChart
              dataLabel={"$"}
              dataBackgroundColors={[
                "#38BDF8",
                "#0595CA",
                "#4249D7",
                "#5D21D2",
                "#CDF2FF",
                "#38BDF8",
              ]}
              dataValues={[
                {
                  label: "Principle and Interest",
                  value: Math.round(
                    getMonthlyPayment(
                      scenario?.purchasePrice - scenario?.downPayment,
                      scenario?.interestRate,
                      scenario?.term / 12,
                    ),
                  ),
                },
                { label: "Taxes", value: scenario?.taxReserves ?? 140 },
                {
                  label: "Insurance",
                  value: scenario?.homeownersInsurance ?? 30,
                },
                {
                  label: "HOA Dues",
                  value: scenario?.homeownersAssociation ?? 120,
                },
                { label: "PMI", value: scenario?.mortgageInsurance ?? 50 },
                {
                  label: "Extra Payment",
                  value: scenario?.earlyPayoffAmount ?? 0,
                },
              ]}
            />
          </Box>
        </Flex>
      </Card.Section>
      <Flex direction="column" gap="sm">
        {[
          {
            title: "Principal & Interest",
            value: getMonthlyPayment(
              scenario?.purchasePrice - scenario?.downPayment,
              scenario?.interestRate,
              scenario?.term / 12,
            ),
            color: "#38BDF8",
          },
          {
            title: "Taxes",
            value: scenario?.taxReserves ?? 140,
            color: "#0595CA",
          },
          {
            title: "Insurance",
            value: scenario?.homeownersInsurance ?? 30,
            color: "#4249D7",
          },
          {
            title: "HOA",
            value: scenario?.homeownersAssociation ?? 120,
            color: "#5D21D2",
          },
          {
            title: "PMI",
            value: scenario?.mortgageInsurance ?? 50,
            color: "#CDF2FF",
          },
          {
            title: "Fees",
            value: scenario?.appraisalFee ?? 80,
            color: "#38BDF8",
          },
        ].map((item) => (
          <Flex align="center" gap="xs" w={"100%"}>
            <Box
              w={12}
              h={12}
              style={{ borderRadius: "50%" }}
              bg={item.color}
            ></Box>
            <Flex align="center" justify="space-between" wrap="wrap" w="100%">
              <Box>
                <Text fw={700} size="sm">
                  {item.title}
                </Text>
              </Box>
              <Box>
                <Text c="dimmed" fw={500} size="sm">
                  {item?.value?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </Text>
              </Box>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Card>
  );
};
