import { FC } from "react";
import "./App.css";
import { ScenarioAdvisorReportPage } from "./components/pages/ScenarioAdvisorReportPage/ScenarioAdvisorReportPage";
import { Route, Routes } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import { scenarioAdvisorProductTourSteps } from "./productTours";

const App: FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/scenario-advisor/report/:reportId"
          element={
            <TourProvider steps={scenarioAdvisorProductTourSteps}>
              <ScenarioAdvisorReportPage />
            </TourProvider>
          }
        ></Route>
      </Routes>
    </div>
  );
};

export default App;
