import { Alert, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { FC } from "react";
import {
  getTotalPayment,
  getTotalInterest,
  //@ts-ignore
} from "@morfi/mortgage-utils";

interface IScenarioSummaryAlertProps {
  activeScenarioIdx: number;
  scenario: {
    purchasePrice: number;
    downPayment: number;
    interestRate: number;
    term: number;
    closingCost: number;
  };
  scenarioCount: number;
}

export const formatUSD = (num: number) => {
  return num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const ScenarioSummaryAlert: FC<IScenarioSummaryAlertProps> = ({
  activeScenarioIdx,
  scenario,
  scenarioCount,
}) => {
  const purchasePrice = +scenario.purchasePrice;
  const downPayment = scenario.downPayment;
  const loanAmount = formatUSD(scenario.purchasePrice - downPayment);
  const interestRate = scenario.interestRate;
  const termYears = scenario.term / 12;
  const totalMonthlyPayment = formatUSD(
    getTotalPayment(purchasePrice - downPayment, interestRate, termYears, 0),
  );
  const totalInterest = formatUSD(
    getTotalInterest(purchasePrice - downPayment, interestRate, termYears),
  );
  const closingCost = scenario.closingCost;

  return (
    <Alert
      id="scenario-summary-banner"
      styles={{
        root: { zIndex: -1 }, // Need this, so the velt cursor doesn't get hidden behind the alert
      }}
      variant="light"
      color="blue"
      title={`Scenario ${activeScenarioIdx + 1} Summary`}
      icon={<IconInfoCircle />}
      radius="md"
    >
      <Text size="sm" fw={700} mb="sm">
        This scenario offers the lowest monthly payment of the {scenarioCount}{" "}
        scenarios presented.
      </Text>
      <Text size="sm">
        The purchase price of the target property is{" "}
        <Text fw={700} component="span">
          {formatUSD(purchasePrice)}
        </Text>
        . The loan amount will be{" "}
        <Text fw={700} component="span">
          {loanAmount}
        </Text>{" "}
        at a{" "}
        <Text fw={700} component="span">
          {interestRate}%{" "}
        </Text>{" "}
        interest rate over{" "}
        <Text fw={700} component="span">
          {termYears} years.{" "}
        </Text>{" "}
        The total cost of the loan will be{" "}
        <Text fw={700} component="span">
          {totalMonthlyPayment}
        </Text>{" "}
        with{" "}
        <Text fw={700} component="span">
          {totalInterest}
        </Text>{" "}
        of interest being paid over the{" "}
        <Text fw={700} component="span">
          {termYears} year
        </Text>{" "}
        term of the loan. Your out of pocket expenses will be{" "}
        <Text fw={700} component="span">
          {formatUSD(closingCost)}
        </Text>{" "}
        due at closing.
      </Text>
    </Alert>
  );
};
