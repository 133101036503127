import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { GetFeedbackParameters, UseFeedbackReturn } from "./types";
import { ScenarioAdvisorReport } from "../../../types";

// custom hook to display streamed LLM feedback from LLM proxy backend
export const useFeedback = (
  scenarioAdvisorReport: ScenarioAdvisorReport,
): UseFeedbackReturn => {
  const [feedback, setFeedback] = useState("");

  // get authenticated user data
  const user = useAuth0();

  // mutation function to fetch and display streamed LLM feedback
  const {
    mutate: getFeedback,
    isLoading: isLoadingFeedback,
    isSuccess: isSuccessFeedback,
    // using an object here as workaround for multiple parameters with useMutation
  } = useMutation(async ({ promptKey, prompt }: GetFeedbackParameters) => {
    // get authenticated user token to access LLM proxy backend
    const token = await user.getAccessTokenSilently();

    // fetch ReadableStream response from LLM proxy backend
    // using fetch because axios doesn't work for streaming responses in browser environments
    const stream = await fetch(
      `${process.env.REACT_APP_LLM_PROXY_BACKEND_URL}/chat`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          promptKey,
          prompt,
          report: scenarioAdvisorReport,
        }),
      },
    );

    // setup to read ReadableStream data and convert them to strings
    const reader = stream.body!.getReader();
    const decoder = new TextDecoder();

    // display streamed feedback
    while (true) {
      // retrieve chunk from stream
      const { value, done } = await reader.read();

      // if done, stop reading stream
      if (done) break;

      // decode chunk and update state with it
      const decodedChunk = decoder.decode(value, { stream: true });
      setFeedback((feedback) => feedback + decodedChunk);
    }
  });

  return [feedback, { getFeedback, isLoadingFeedback, isSuccessFeedback }];
};
