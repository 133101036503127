import { Card, Flex, Button, Title, Image } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { FC } from "react";

interface ICompanyCardProps {
  companyLogo?: string;
}

export const CompanyCard: FC<ICompanyCardProps> = ({ companyLogo }) => {
  return (
    <Card
      withBorder
      bg="white"
      radius="md"
      id="broker-company-info-card"
      w={"100%"}
      h="100%"
    >
      <Flex gap="sm" direction="column">
        <Image
          fit="contain"
          src={companyLogo}
          mah={200}
          fallbackSrc="https://placehold.co/600x400?text=Company Logo"
        ></Image>
        <Title order={5} ta="center">
          C2 Financial - Everett Branch
        </Title>
        <Button variant="subtle" rightSection={<IconArrowRight size={16} />}>
          Go to website
        </Button>
      </Flex>
    </Card>
  );
};
